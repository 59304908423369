.funding-categories {
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;
	padding: 0 10px;

	.funding-category {
		display: grid;
		column-gap: 12px;
		grid-template-columns: auto 140px 1fr;
		grid-template-areas:
			"icon details input-container"
			"icon details input-container";

		.icon {
			grid-area: icon;
			display: flex;
		}

		.details {
			margin-top: 2px;
			display: flex;
			flex-direction: column;
			gap: 3px;
			grid-area: details;

			.label {
				font-family: "BalooDa2SemiBold";
				line-height: 20px;
			}

			.description {
				font-size: 0.75rem;
				font-family: "BalooDa2Medium";
				line-height: 15px;
			}
		}

		.input-container {
			grid-area: input-container;
			display: flex;
			flex-direction: column;

			.edit {
				border: none;
				background: none;
				padding: 0;
				margin: 0 0 0 auto;
			}

			.value {
				text-align: right;
				font-family: "BalooDa2Bold";
				font-size: 1.25rem;
				height: fit-content;
				display: flex;
				justify-content: flex-end;

				.my-input input {
					max-width: 100px;
				}
			}
		}

		&:last-child {
			.input-container .value .my-input input {
				max-width: 55px;
			}
		}
	}

	@media screen and (max-width: 400px) {
		.funding-category {
			grid-template-columns: auto 100px 1fr;
		}
	}

	@media screen and (min-width: 840px) {
		grid-template-columns: 1fr;
		column-gap: 2%;

		.funding-category {
			&:last-child {
				grid-template-columns: auto 1fr;
				grid-template-areas:
					"icon details"
					"input-container input-container ";

				.edit {
					display: none;
				}

				.input-container {
					margin-top: 10px;
				}
			}
		}
	}

	@media screen and (min-width: 1100px) {
		column-gap: 4%;
		grid-template-columns: repeat(2, 48%);
	}
}
